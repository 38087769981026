export default [
  {
    path: "/",
    name: "Home",
    meta: {
      authRequired: false,
    },
    component: () => import("@/views/landing_pages/Home.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/dashboard/index"),
  },
  {
    path: "/user/entities",
    name: "My Entities",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/dashboard/myentities.vue"),
  },

  // ##################### ENTITY ROUTES ###################

  {
    path: "/entity/:id/dashboard",
    name: "Entity Dashboard",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/home/index.vue"),
  },
  {
    path: "/entity/:id/course/add",
    name: "New Course",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/NewCourse.vue"),
  },
  {
    path: "/entity/:id/courses",
    name: "Courses",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/Courses.vue"),
  },
  {
    path: "/entity/:id/course/:courseId",
    name: "Course Detail",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/CourseDetails.vue"),
  },
  {
    path: "/entity/:id/course/:courseId/edit",
    name: "Edit Course",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/EditCourse.vue"),
  },
  {
    path: "/entity/:id/course/:courseId/test/:contentId",
    name: "Course Contents",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/contents/Content.vue"),
  },
  {
    path: "/entity/:id/course/:courseId/test/:contentId/edit",
    name: "Edit Test",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/contents/EditTest.vue"),
  },
  {
    path: "/entity/:id/course/:courseId/questions",
    name: "Questions",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/entityAdminDashboard/course/contents/Questions.vue"
      ),
  },
  {
    path: "/entity/:id/course/:courseId/content/:contentId/edit",
    name: "Edit Content",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/entityAdminDashboard/course/contents/EditVideo.vue"
      ),
  },
  {
    path: "/entity/:id/course/:courseId/content/add",
    name: "Add Content",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/entityAdminDashboard/course/contents/AddContent.vue"
      ),
  },
  {
    path: "/entity/:id/course/:courseId/passed-tests/:contentId",
    name: "Passed Test",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/users/UsersTest.vue"),
  },
  {
    path: "/entity/:id/course/:courseId/enrolled-users",
    name: "Enrolled Users",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/views/pages/entityAdminDashboard/course/users/UsersEnrolled.vue"
      ),
  },
  {
    path: "/entity/:id/courses/:courseId/e-cert-users",
    name: "E-Cert Users",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/entityAdminDashboard/course/users/eCertUsers.vue"),
  },

  // #################### USER ROUTES ####################

  {
    path: "/user/:id/courses",
    name: "User Courses",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/Courses.vue"),
  },
  {
    path: "/user/:id/course/:courseId/details",
    name: "User Course Detail",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/CourseDetails.vue"),
  },
  {
    path: "/user/:id/enroll_courses",
    name: "Enroll Courses",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/myCourses/MyCourses.vue"),
  },

  {
    path: "/user/:id/enroll_course/:courseId/details",
    name: "Enroll Course Detail",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/myCourses/MyCourseDetails.vue"),
  },

  {
    path: "/user/:id/enroll_course/:courseId/test/:enrollmentId/:testId",
    name: "Course Test",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/tests/Test.vue"),
  },
  {
    path: "/user/:id/enroll_courses/certificates",
    name: "User Certificate",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/myCourses/ECerts.vue"),
  },
  {
    path: "/user/:id/courses/:category_slug",
    name: "Courses Category",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/Categories.vue"),
  },
  {
    path: "/user/:id/certificates",
    name: "Certified User",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/myCourses/EcertSearch.vue"),
  },

  // #################### HIRING ROUTES ####################
  {
    path: "/user/:id/recruiter-profile",
    name: "Recruiter Profile",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/eHiring/Recruiter/Profile.vue"),
  },
  {
    path: "/user/:id/hiring-request",
    name: "Hire User",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/eHiring/SendHire.vue"),
  },
  {
    path: "/user/:id/recruiter-requests",
    name: "Recruiter Requests",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/eHiring/RecruiterRequests.vue"),
  },
  {
    path: "/user/:id/technician-requests",
    name: "Technician Requests",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/user/eHiring/TechnicianRequests.vue"),
  },
  {
    path: "/user/:id/retrieve-request",
    name: "Retrieve Hire User",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/user/eHiring/RetrieveHire.vue"),
  },
  {
    path: "/user/:id/technician-details/:technicianId",
    name: "Technician Details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/pages/user/eHiring/Technician/TechnicianDetails.vue"),
  },
];
