/* eslint-disable no-unused-vars */
import axios from "axios";
import url from "@/helpers/url";
import Storage from "@/helpers/storage.js";

const token = `${Storage.get("currentUser").email}`;

export const state = {
  hired: [],
  retrieve: [],
  recruiterrequests: [],
  technicianrequests: [],
};

//action
export const actions = {
  getHire({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/green4d/recruit/request/`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("HIRE", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTechnician({ commit }, individual_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/recruit/technician/${individual_id}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addTechnician({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url()}/api/green4d/recruit/add-technician-info/`, data, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTechnician({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${url()}/api/green4d/recruit/technician/${
            data.technician_info.individual_id
          }/`,
          data,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  hireAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url()}/api/green4d/recruit/request/${data.request_id}?action=${
            data.action
          }`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  retrieveHire({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/recruit/retrieve-request/${userId}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("RETRIEVE_HIRE", response.data);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAllRecruiterRequests({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url()}/api/green4d/recruit/request/list/recruiter/${userId}/`, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("ALL_REQUEST", response.data.requests);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAllTechnicianRequests({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url()}/api/green4d/recruit/request/list/technician/${userId}/`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            commit("ALL_RECRUITER_REQUEST", response.data.requests);
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

//mutaions
export const mutations = {
  HIRE(state, technician) {
    state.hired.push(technician);
  },
  RETRIEVE_HIRE(state, requests) {
    state.retrieve = requests;
  },
  ALL_REQUEST(state, requests) {
    state.recruiterrequests = requests;
  },
  ALL_RECRUITER_REQUEST(state, requests) {
    state.technicianrequests = requests;
  },
};

// Getters
export const getters = {
  allrecruiterrequests: (state) => state.recruiterrequests,
};
