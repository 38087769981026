let service = () =>{
    let service
    if(process.env.NODE_ENV === 'development') {
        service = `http://localhost:8086`
    } else {
        service = `https://test.green.4dcorridor.org/`   
       
    }
    return service
}

export default service;